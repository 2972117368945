:root {
  --primary-color: #FAFAFA;
  --secondary-color: #0E9B8F;
  --font-family: "Jost";
  --heading-font-size: 44px;
  --heading-font-weight: 700;
  --social-font-size: 24px;
  --social-font-weight: 600;
}
a{
  text-decoration: none !important;
}
p{
  color: #616161;
text-align: justify;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 162.5% */
}

h1 {
  color: var(--primary-color);
  text-align: center;
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-style: normal;
  font-weight: var(--heading-font-weight);
}
h2{
  color: #222;
font-family: "Mohave";
font-size: 32px;
font-style: normal;
font-weight: 500;
 /* 62.5% */
}
h5{
  color: #222;
font-family: "Mohave";
font-size: 20px;
font-weight: 500;
/* 100% */
}
.carousel-caption {
  position: absolute;
  bottom: 17rem;
  left: 51%;
  transform: translateX(-50%);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--primary-color);
  text-align: center;
  width: 70%;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 35%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.carousel-indicators .active {
  background-color: var(--primary-color);
  opacity: 1;
}

.btn-header {
  border-radius: 8px !important;
  background: var(--secondary-color);
  padding: 10px 20px;
  color: var(--primary-color);
  border: 0;
}

.Social-media {
  color: var(--primary-color);
  text-align: center;
  font-size: var(--social-font-size);
  font-style: normal;
  font-weight: var(--social-font-weight);
}

.Social-media a {
  text-decoration: none !important;
  color: var(--primary-color);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  border-radius: 50%;
  border: 2px solid white;
}
.count{
  color: #222;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

}
h5{
  color: #222;
  font-family: "Mohave";
  font-size: 20px;
  font-weight: 400;
}
.Section-time{
  background-color: #E5E5E5;
}
.point-package{
  position: relative;
  bottom: 2px;
}
.text-warning{
  color: #F18B16 !important 

}
.download-text {
  color: #222;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
margin-bottom: 1px !important;
text-align: start;
}
a{
  text-decoration: none;
  color: #222;
}
.my-80{
  margin-top: 80px;
  margin-bottom: 80px;
}
.bg-info{
background-color: #0E9B8F !important;
border-radius: 12px;
}
.package-places{
  color: #49206D;
text-align: center;
font-family: "Mohave";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height :normal;
 /* 100% */
}
.Packages-prices{
color: #49206D;
text-align: center;
font-family: "Mohave";
font-size: 24px;
overflow: hidden;
font-weight: 600;


/* 83.333% */
}
.text-offer{
  color: #49206D;
text-align: center;
overflow: hidden;
font-family: "Mohave";
font-size: 52px;
font-style: normal;
font-weight: 600;
line-height: 52px; /* 104.167% */
}
.person-contant{
color: #49206D;
text-align: center;
font-family: "Mohave";
font-size: 16px;
font-style: normal;
font-weight: 500;
 /* 125% */
}
.package-border{
  border-radius: 16px;
border: 0.4px solid #0E9B8F;
background: #EEFEFF;
padding: 20px;
}
.custom-border-end {
  border-right: 1px solid #0E9B8F; /* Change #4a90e2 to any color you prefer */
}
.itinerary-container {
  width: 100%;
  padding: 20px;
}

.itinerary-heading{
  font-size: 20px;
  color: #333 !important;
  font-weight: 540;
  font-family: "Mohave";
  letter-spacing: .2px;
}


.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.itinerary-button {
  padding: 10px 15px;
  background-color: #e6f7ff;
  border: 1px solid #b3e0ff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.itinerary-button:hover {
  background-color: #d1ecff;
}

.description {
  font-size: 14px;
  margin-top: 15px;
}

.image-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.itinerary-image {
  width: 200px;
  height: auto;
  border-radius: 8px;
}
.btn-Itenary{
border-radius: 4px;
background: #EEE;
padding:7px 20px;
border: 0px;
color: #0E9B8F;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
}

.accordion-button {
  background-color: #Fff !important;
  color: #FAFAFA;
  font-weight: bold;
  padding: 15px;
  cursor: pointer;
}

.accordion-button h3{
  font-size: 1.3rem !important;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}





.accordion-item {
  margin-bottom: 15px;
}
/* Add this to your stylesheet */
.border-none {
  border: none !important;
  border-bottom: 1px solid #D3D3D3 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.custom-list-item{
  color: #606969;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: justify;

  margin-bottom:14px;
}
 /* 100% */

.text-purple{
  color: #6A3698;
text-align: center;
font-family:"Mohave";
font-size: 32px;
font-style: normal;
font-weight: 600;

}
/* footer.css */

footer {
  background: linear-gradient(to right, #8633D1, #7686ED);

 background-repeat: no-repeat;
 background-size: cover;
 padding-bottom: 100px !important;
 padding: 40px 0;
}

footer h4,
footer h5 {
  color: white;
}

footer p {
  color: white;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer ul li {
  color: white;
  margin-bottom: 8px;
  color: #FFF;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-underline-offset: 4px;
  text-decoration-line: underline;
}



footer .social-icons {
  margin-top: 1px;
}

footer .social-icons svg {
  color: white;
  margin-right: 10px;
  cursor: pointer;
}

footer .social-icons svg:hover {
  color: #ddd;
}

footer .contact-info p {
  margin: 0;
  color: white;
}

.contact-info h2 {
  font-size: 32px;
  font-family: Mohave;
  font-weight: 600;
}

.BookOneTour{
color: #FAFAFA;
font-family: "Mohave";
font-size: 40px;
font-style: normal;
font-weight: 600;

}
.QUICK-links{
  color: #FAFAFA;
font-family: "Mohave";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.img-social{
  width: 70%;
}
.border-Frequently{
  /* background: #EEFEFF !important;  */
}
.py-80{
  padding-top: 80px;
  padding-bottom: 80px;
}
h3{
  color: #222;
font-family: "Josefin Sans";
font-size: 20px;
font-style: normal;
font-weight: 500;

}
/* *{
  overflow-x: hidden;
} */


/* Style for carousel control buttons */


/* Override the default background image to customize the arrow */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #222;
 /* Black background for the control icon */
  background-size: 70%, 70%;
  border-radius: 50%; /* Optional: make the background a circle */
}

/* Custom arrow icons in white */

/* Adjust the arrow positioning */
.carousel-control-prev-icon::after {
  transform: rotate(-135deg); /* Rotate to make a left arrow */
  margin-left: 4px; /* Adjust positioning */
}

.carousel-control-next-icon::after {
  transform: rotate(45deg); /* Rotate to make a right arrow */
  margin-right: 4px; /* Adjust positioning */
}
.avatar {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  object-fit: cover;
   margin-right: 10px;
}
.card-title{
color: #222;
font-family: "Mohave";
font-size: 20px;
font-style: normal;
font-weight: 500;

}
.card-subtitle{
color: #606969;
text-align: justify;
font-family: "Josefin Sans";
font-size: 12px;
font-style: normal;
font-weight: 360;

}
.card{
  border-radius: 12px !important;
background: #FFF !important;
box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.20) !important;
}

.carousel__subtitle {
  margin-bottom: 24px;
}

.carousel__wrapper {
  display: flex;
  align-items: center;
}



.carousel__button--prev {
  margin-right: 8px; border: 1px solid #222222;
  color:#000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.carousel__button--next {
  margin-left: 8px;
  border: 1px solid #222222;
  color:#000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.carousel__cards {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 14px;
}

.carousel__card {
  margin: 0 8px;

  box-shadow: 0px 2px 10px rgb(184, 176, 176) ;
  border-radius: 8px;
  border-radius: 12px;
  background: #FFF;
  overflow: hidden;
}

.carousel__image {
  width: 100%;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
  object-fit: cover;
}

.carousel__content {
  padding: 16px;
}

.carousel__package-title {
  margin-bottom: 8px;
  color: var(--naruralblack, #222);
  font-family: Mohave;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.carousel__description {
color: #5A5A5A;
}

.carousel__descriptionnn {
  color: #5A5A5A;
  font-family: Mohave;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.carousel__price {
  margin-top: 12px;
}

.Person-rate{
color:  #5A5A5A;
font-family: "Poppins";
font-size: 10px !important;
font-style: normal;
font-weight: 400;
}

h1 , h2, h3, h4 ,h5, h6 {
overflow: hidden;
}

.Close-items{
  color: rgba(236, 60, 60, 1);
 }
.count{
  text-align: center;
}
 .Open-items{
   color: #1CC671;
 }
 
 .form-head{
  color: #333;
  font-family: "Josefin Sans";
  font-size: 24px ;
  font-style: normal;
  font-weight: 600; }

.my-100{
  margin-bottom: 100px;
  margin-top: 100px;
}
/* Hide the popup form by default */
.form-popup {
  display: none;

}

/* Show the popup form only on mobile screens (max-width: 576px) */

.carousel__cards {
  overflow: hidden;
  width: 100%;
  padding: 20px;
}


/* Default size for larger screens */
.img-social {
  width: 40px; /* Adjust this as needed */
  height: auto;
}
.mx-10{
  margin-left: 20px;
  margin-right: 20px;
}

/* Adjust sizes for smaller screens */


@media (max-width: 576px) {
  .img-social {
    width: 30px; 
   /* Even smaller size for extra-small devices */
  }
  .get-a-quote{
  /* margin: 20px !important; */
  width:90% !important;
  }
  .get-a-quote button{
    font-size: 14px;
  }
  .get-a-quote p{
    font-size: 16px !important;
    text-align: center;
  
  }
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 18px;
  }
  .download-text{
    text-align: 18px;
    margin-top: 20px;
    text-align: center;
  }
  p{
    font-size: 14px;
  }
  .download-pdf
  {
    text-align: 18px;
    margin-top: 20px;
  }
  .custom-border-end {
 /* or any specific value */
    border-bottom: 1px solid #0E9B8F !important;

    border: none;
}
.carousel-item {
  flex-direction: row;
}
.person-contant{
 padding-bottom: 10px;
}

  .package-places{
    font-size: 18px;
  }
  .insta-btn {
    position: fixed;
    top: 90%;
    left: 10px;
    width:80px;
    border-radius: 50%;
    background-color:#0E9B8F;  /* Customize as needed */
}
.custom-list-item{
   font-size: 14px;
}
.person-contant





.insta-btn i, .whatsapp-btn i {
    font-size: 24px;
}
.mx-10{
  margin-left: 0px;
  margin-right: 0px;
}
.form-head{
  font-size: 14px;
}

.carousel-item {
  flex-direction: column;
  align-items: center;
}

}
h5{
  font-size: 18px;
}
.person-contant{
  overflow: hidden !important;

 
}
.download-pdffile{
  text-decoration: underline;
  color: #F18B16;


text-align: center;

font-family: "Mohave";
font-size: 16px;
font-style: normal;
text-underline-offset: 4px;
text-decoration-line: underline;
}

.btn-header {
  display: flex;
  align-items: center;
}
.custom-btn {
  background-color: #FAFAFA;
  color:#0E9B8F; 
  border: 1px solid #d4d4d4; 
padding: 8px;
  border-radius: 5px;

  font-family: "Josefin Sans";
  font-size: 16px;

  font-weight: 400;
  transition: all 0.3s ease;
}

.custom-btn:hover {
  background-color: #cfe3d6; /* Slightly darker green on hover */
  border-color: #b5c7ba;
}

@media (max-width: 768px) {
  .custom-btn {
    width: 100%; /* Full-width on smaller devices */
    margin-bottom: 10px;
  }
}


.btn-black{
  color: #222;
}

.btn-blue{
  background-color: #0aadf0 ;
  color: #fafafa;
}

.btn-line--blue{
  padding: 12px 24px;
  border: 2px solid #0aadf0;
  background-color: #fff;
  -webkit-transition: all .3s ease-out;
}

.btn-warning:hover{
 color: #FAFAFA !important;
  background: #F18B16 !important;
}
.green-backgroundImage{
  background-image: url("Assets/images/green.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
/* Adjust the carousel for mobile devices */



/* Center the text and adjust size for mobile devices */
.mobile-text-center {
  text-align: center;
  font-size: 14px; /* Adjust as needed */
}

/* Ensure button is centered on mobile */



.button-custom {
  display: inline-block;
  margin: 5px;
}

.custom-btn {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
 
  cursor: pointer;
  text-align: center;
}

/* Media query for mobile screens */
@media (max-width: 767.98px) {
  .button-custom {
    width: 44%; /* Two buttons per row */
  }

}
/* Media query for 1024px screens */
@media (min-width: 1024px) and (max-width: 1220px) {
  .download-text{
    font-size: 12px;
  }
  .download-pdf{
    font-size: 10px;
  }
  .package-places{
  font-size: 16px;
  }
  
}
.viewpoints-icons {
  list-style-type: none;
  display: flex;
  flex-direction: row; 
  text-align: center;
  align-items: center; 
  padding: 0px;
}

.viewpoints-icons li {

  margin: 0px 12px 0px 0px; /* Adds space between icons */
  
}

@media (min-width: 1200px) {
  .viewpoints-icons li {
    margin: 0px 20px 8px 0px; /* Adds space between icons */
  }
  
}

.viewpoints-icons img {
  width: 30px; /* Adjust as needed */
  height: auto;
}
.icon-text{
  font-size: 6.85px;
  font-weight: 700;
  font-family: "Josefin Sans";
  color: #222222;
  
}
.why-bookonetour-section{
  background:linear-gradient(260deg, rgba(134, 51, 209, 0.16) 0%, rgba(118, 134, 237, 0.16) 78.38%);
padding-top: 80px;
padding-bottom: 80px;
}




.whybot-para{
  color: #222;
  leading-trim: both;
  text-edge: cap;
  font-family: Mohave;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.des_nav_items li{
  overflow: hidden;

}

.why-bookonetour-section h1{
  color: #3C1A5B;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Mohave;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}






/* Mobile Styles */
@media (max-width: 576px) {
  .contact-contanier .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .largest {
    width: 100% !important;
    text-align: center;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 20px;
  }

  .contact-form button {
    width: 100%;
    padding: 16px;
  }
}

/* Tablet (Portrait) Styles */
@media (min-width: 577px) and (max-width: 768px) {
  .contact-contanier .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .largest {
    width: 100% !important;
    text-align: center;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 20px;
  }
}

/* Tablet (Landscape) Styles */
@media (min-width: 769px) and (max-width: 992px) {
  .contact-contanier .contact-info {
    max-width: 100%;
    padding: 20px;
  }
  .contact-form {
    justify-content: center;
    width: 100%;
    margin-left: 250px;
  }
  .largest {
    width: 100% !important;
    text-align: center !important;
  }

  .contact-contanier {
    display: block !important;
  }

  .contact-form {
    margin-top: 30px;
  }
}

/* Small Desktop Styles */
@media (min-width: 993px) and (max-width: 1024px) {
  .contact-contanier .contact-info,
  .contact-form {
    max-width: 80%;
    padding: 40px;
  }
}

/* Medium Desktop Styles */
@media (min-width: 1025px) and (max-width: 1200px) {
  .contact-contanier .contact-info,
  .contact-form {
    max-width: 70%;
    padding: 40px;
  }
}

/* Large Desktop Styles */
@media (min-width: 1201px) and (max-width: 1440px) {
  .contact-contanier .contact-info,
  .contact-form {
    max-width: 60%;
    padding: 50px;
  }
}

/* Extra Large Desktop Styles */
@media (min-width: 1441px) {
  .contact-contanier .contact-info,
  .contact-form {
    max-width: 50%;
    padding: 50px;
  }
}

.largest {
  text-align: center;
  width: 1050px;
}
.contact-form1{
  width: 100%;
  max-width: 900px; 
  margin: 0 auto;
  padding: 1px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
}

.contact-form1 > div {
  flex: 1 1 calc(50% - 20px); /* Adjusts the width to be half of the container minus the gap */
  box-sizing: border-box;
  margin-bottom: 0; /* Remove bottom margin to avoid extra space */
}

.contact-form1 label {
  display: block;
  margin-bottom: 5px;
  text-align: start;
  font-size: 14px;
}

.contact-contanier .contact-form1 input,
.contact-form1 select,
.contact-form1 textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.contact-contanier .contact-form1 input:focus,
.contact-form1 select:focus,
.contact-form1 textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-contanier form p{
  margin-bottom: 0rem;
}





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@media (min-width: 992px) {
  .reviewscard {
      flex: 0 0 auto;
      width: 30%;
  }
}

.card_space{
  margin-bottom: 80px;
  padding: 0px 24px ;
}

.perperson{
  font-size: 15px !important;
  color: #444 !important;
  margin-bottom:0px !important;
}

.bg-infoo{
  /* background-color: #19945E !important; */
  background:linear-gradient(318deg,rgba(156,205,251,.6),hsla(31,95%,78%,.6));
  border-radius: 12px;
  padding: 40px 20px 20px 20px;
  }

.bg-infooo{
  /* background-color: #19945E !important; */
  background:linear-gradient(318deg,rgba(156,205,251,.6),hsla(31,95%,78%,.6));
  border-radius: 12px;
  padding: 20px !important;
  }

  .btn-warning{

    background: #fce4ca !important;
      color: #333;
      font-family: "Josefin Sans";
      font-size: 20px ;
      font-style: normal;
      font-weight: 600;
  }

  @media (max-width: 1024px) {
    .btn-warning{

      background: #fce4ca !important;
        color: #333;
        font-family: "Josefin Sans";
        font-size: 18px ;
        font-style: normal;
        font-weight: 600;
    }
  }

  .booknowbtn{
    margin-bottom: 44px;
  }
    
  .btn-bknw{
    background: #f79d37 !important;
    color: #333;
    font-family: "Josefin Sans";
    font-size: 18px ;
    font-style: normal;
    font-weight: 600;
    box-shadow: none;
    padding: 12px 0px 10px 0px;
  }

  .overview{
  padding: 0px 40px;
}
@media (max-width: 1024px) {
  .overview{
    padding: 0px 20px;
  }
}
@media (max-width: 1024px) {
  .btn-bknw{
    font-size: 16px ;
    font-style: normal;
    font-weight: 600;
    box-shadow: none;
    padding: 10px 4px 8px 4px;
  }
}

.googleratings{
  color: #F3C409 !important;
  font-size: 26px;
  font-weight: 500;
}
.googlereviews{
font-size: 18px;
font-family: 'Mohave';
font-weight: 400;
color: #333 !important;
overflow: visible ! important;
}

.price-booknow{
  font-size: 28px;
  font-family: "mohave";
  font-weight: 600;
  line-height: 0px;
  color: #222;
  padding-top: 18px;
  margin-bottom: 6px;
}

